import { Location, ViewportScroller } from '@angular/common';
import { AfterViewChecked, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbModalConfig, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { distinctUntilKeyChanged, map, take, tap } from 'rxjs/operators';
import { WindowRef } from './_global/window-ref.module';
import * as fromApp from './_store/app.reducers';
import tools from './_tools';
import { AuthService } from './components/main/auth/auth.service';
import * as authActions from './components/main/auth/store/auth.actions';
import { SelectFormMode, SetDefaultEmail } from './components/main/auth/store/auth.actions';
import * as fromAuth from './components/main/auth/store/auth.reducer';
import { FormMode } from './components/main/auth/store/auth.reducer';
import * as fromNotifications from './components/main/notifications/store/notifications.reducer';
import { AccountService } from './components/pages/account/account.service';
import { SetAccountColors } from './components/pages/account/store/account.actions';
import { AccountColor } from './components/pages/account/store/account.reducer';
import { GetFriendsCurrent, GetFriendsNoAction, GetFriendsRequests, GetUserAccountTierInfo, TryGetUserInfo } from './components/pages/profile/store/profile.actions';
import { AuthGuardService } from './directives/auth-guard/auth-guard.service';
import { QuestService } from './_services/quest.service';
import { CommonService } from './_services/common.service';
import { NotifierService } from 'angular-notifier';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewChecked {
  authState: Observable<fromAuth.State>;
  notificationsInfoState: Observable<fromNotifications.State>;
  hideHeader = true;
  showNotifications = false;

  displayUserLockElem: boolean = false;
  
  @ViewChild('successNotification', { static: true }) successNotification;

  @ViewChild('warningNotification', { static: true }) warningNotification;
  
  @ViewChild('errorNotification', { static: true }) errorNotification;
  
  @ViewChild('infoNotification', { static: true }) infoNotification;

  constructor(
    private winRef: WindowRef,
    private store: Store<fromApp.AppState>,
    public authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private authGuardService: AuthGuardService,
    private ngbTooltipConfig: NgbTooltipConfig,
    private modalConfig: NgbModalConfig,
    private accountService: AccountService,
    private viewport: ViewportScroller,
    private questService: QuestService,
    private commonService: CommonService,
    private notifier: NotifierService
    ) {
  }

  ngOnInit() {
    this.modalConfig.centered = true;
    this.modalConfig.size = 'lg';
    this.modalConfig.scrollable = true;

    this.authState = this.store.select('auth');
    this.authState.pipe(take(1)).subscribe(
      tools.debounce((authState: fromAuth.State) => {
        if (authState.authenticated) {
          this.authService.checkTokenValid(authState);
        }
      }, 0)
    );

    this.store.select('userInfo').pipe(take(3)).subscribe(userInfo => {
      // get the user account colors
      this.authState.pipe(take(1)).subscribe(auth => {
        if (auth.authenticated) {
          this.store.select('account').pipe(take(1)).subscribe(accState => {
            if ((!accState.accentColor || (accState.accentColor && accState.accentColor === '')) &&
              (!accState.themeColor || (accState.themeColor && accState.themeColor === ''))) {
              this.getUserAccountColors(userInfo.id);
            }
          })
          
          this.store.dispatch(new GetUserAccountTierInfo(userInfo.id));

          this.store.dispatch(new authActions.UpdateLastLogin(userInfo.email));
        }
      });
    });

    this.store.select('auth').subscribe(authState => {
      if (authState.authenticated) {
        this.store.select('userInfo').subscribe(userInfoState => {
          if (userInfoState.id && !userInfoState.hasGotCurrentFriends) {
            this.store.dispatch(new GetFriendsCurrent());
          }
    
          if (userInfoState.id && !userInfoState.hasGotFriendsNoAction) {
            this.store.dispatch(new GetFriendsNoAction());
          }
    
          if (userInfoState.id && !userInfoState.hasGotFriendRequests) {
            this.store.dispatch(new GetFriendsRequests());
          }
        });
      }
    });

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        // $('html').scrollTop(0);
        this.viewport.scrollToPosition([0, 0]);
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

        document.body.classList.remove('modal-open');
        this.hideHeader = this.route.snapshot.queryParams['_web-view'] === 'true' ? true : (
          this.route.snapshot.firstChild.data[0]
            ? this.route.snapshot.firstChild.data[0].hideHeader
            : false
        );

        setTimeout(() => {
          this.viewport.scrollToPosition([0, 0]);
          // $('html').scrollTop(0);
        }, 10);
      }
    });  

    this.route.queryParams.subscribe((params) => {
      if (params && params.token) {
        const now = new Date();
        const data = this.parseJwt(params.token);
        this.store.dispatch(new authActions.SetToken({token: params.token, tokenUpdateTime: now.getTime()}));
        this.store.dispatch(new authActions.SetExpires(data.exp));
        this.store.dispatch(new authActions.Signin());
        this.store.dispatch(new TryGetUserInfo());
        this.store.dispatch(new authActions.AuthSetLoadingState(false));
      }
    });

    // check if it is google login by getting and checking the value of state
    this.route.fragment.subscribe(fr => {
      console.log('150', fr);
      // check for 
      let splitFragment = fr && fr.split("&") || [];
      if (splitFragment && splitFragment.length > 0) {
        const stateFrag = splitFragment.find(_frag => _frag.match('state='));
        // checking if login type is google
        if (stateFrag.match(/(state=diemlife_google_authorization)/g)) {
          // check if any error 
          const errorFrag = splitFragment.find(_frag => _frag.match('error='));
          if (errorFrag && errorFrag !== '') {
            this.notifier.notify('error', 'Could not login into Google.');

            return;
          }

          // get the access token
          const accessTokenFrag = splitFragment.find(_frag => _frag.match('access_token='));
          const accessToken = accessTokenFrag.substring(('access_token=').length, accessTokenFrag.length);

          // get user data from Google
          this.authService.getUsersInformationGoogle(accessToken)
            .subscribe(googleRes => {
              console.log('160 googleRes', googleRes);
              if (!googleRes || (googleRes && !googleRes.user)) {
                this.notifier.notify('error', 'Issue processing request.');

                return;
              }

              const splitName = googleRes.user.displayName.split(' ');
              const lastName = splitName.length > 1 ? splitName.splice(0, 1) : '';

              this.store.dispatch(new authActions.TrySigninSocial({
                id: googleRes.user.permissionId || '',
                name: googleRes.user.displayName || '',
                email: googleRes.user.emailAddress || '',
                firstName: splitName[0] || '',
                lastName: splitName.length > 1 ? splitName.join(' ') : '',
                imageUrl: googleRes.user.photoLink || '',
                provider: 'GOOGLE'
              }));
            })
        }
      }
    })

    this.store
      .pipe(
        select('notifications'),
        distinctUntilKeyChanged('active'),
        map(state => state.active),
        tap((isActive) => {
          this.showNotifications = isActive;
        })
      )
      .subscribe();

    this.store.select('quest').subscribe(questState => {
      this.displayUserLockElem = questState.userNotAllowedToView;
    });

    /*
    this.commonService.getNotification().subscribe(res => {
      console.log('show notification getNotification()', res);
      if (res && res.notifType && res.notifMsg) {
        switch (res.notifType) {
          case 'success':
            this.notifier.show({
              message: res.notifMsg,
              type: 'success',
              template: this.successNotification
            });
            break;

          case 'warning':
            this.notifier.show({
              message: res.notifMsg,
              type: 'warning',
              template: this.warningNotification
            });
            break;

          case 'error':
            this.notifier.show({
              message: res.notifMsg,
              type: 'error',
              template: this.errorNotification
            });
            break;

          case 'info':
            this.notifier.show({
              message: res.notifMsg,
              type: 'info',
              template: this.infoNotification
            });
            break;
            
          default:
            break;
        }
          
      }
    });
    */

    const currentDate = moment.utc(new Date());
    setInterval(() => {
      // if page was loaded 24hr before
      if (moment.utc(new Date()).diff(currentDate) > (24 * 60 * 60 * 1000)) {
        window.location.reload();
      }
    }, (2 * 60 * 1000)); //2 min
  }

  parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  ngAfterViewChecked() {
    if (typeof $ !== 'undefined' && $(this.winRef.nativeWindow).width() < 768) {
      this.ngbTooltipConfig.triggers = 'click';
    }
  }

  setupFormOptions(event: { formMode: string, defaultEmail: string }) {
    this.store.dispatch(new SelectFormMode(FormMode[event ? event.formMode : 'logIn']));
    this.store.dispatch(new SetDefaultEmail(event ? event.defaultEmail : null));
  }

  onCloseModal() {
    this.authGuardService.update();
    this.store.dispatch(new SelectFormMode(null));
    this.store.dispatch(new SetDefaultEmail(null));
  }

  getUserAccountColors(userId: number): void {
    this.accountService.getCustomColor(userId)
      .subscribe((res: AccountColor) => {
        this.store.dispatch(new SetAccountColors(res));
        if (res.accentColor && res.themeColor && 
          res.accentColor !== '' && res.themeColor !== '') {
          this.accountService.changeRootColor(res, userId);
        }
      })
  }
}
