import { QuestView } from 'src/app/_interface/my-quests.types';
import * as MyQuestsActions from './my-quests.actions';

export interface State {
  active: number;
  saved: number;
  completed: number;
  allQuests: QuestView[];
}

export const initialState: State = {
  active: 0,
  saved: 0,
  completed: 0,
  allQuests: []
};
export function MyQuestsReducer( state = initialState, action: MyQuestsActions.MyQuestsActions ) {
  switch (action.type) {
    case (MyQuestsActions.MY_QUESTS_UPDATE_STATE):
      return {
        ...state,
        ...action.payload
      };
      
    case MyQuestsActions.ALL_MY_QUESTS:
      return {
        ...state,
        allQuests: action.payload
      }
    default:
      return state;
  }
}
