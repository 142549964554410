import { Action } from '@ngrx/store';
import * as fromMyQuestsState from './my-quests.reducer';
import { QuestView } from 'src/app/_interface/my-quests.types';

export const MY_QUESTS_UPDATE_STATE = 'MY_QUESTS_UPDATE_STATE';
export const ALL_MY_QUESTS = 'ALL_MY_QUESTS';

export class MyQuestsUpdateState implements Action {
  readonly type = MY_QUESTS_UPDATE_STATE;

  constructor(public payload: fromMyQuestsState.State) {}
}

export class UpdateAllMyQuests implements Action {
  readonly type = ALL_MY_QUESTS;

  constructor(public payload: QuestView[]) {}
}

export type MyQuestsActions = MyQuestsUpdateState
  | UpdateAllMyQuests;
