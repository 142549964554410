import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import { Subject } from 'rxjs';
import { finalize, take, timestamp } from 'rxjs/operators';
import { QuestService } from 'src/app/_services/quest.service';
import { LOG_ACTIVITY_POST_CHARACTER_LIMIT, QUILL_CONFIG } from 'src/app/app.config';
import { environment } from 'src/environments/environment';
import { LoggedActivity, QuestGalleryImage, QuestTask, SelectedActivityForTask } from '../../../_interface/quest.types';
import { GetQuestGalleryAssets, QuestNewLoggedActivity, UpdateQuestLoggedActivity } from '../../../_interface/store/quest-detail.actions';
import { AppState } from '../../../_store/app.reducers';
import { LogActivityService } from 'src/app/_services/log-activity.service';
import { CommentService } from '../../pages/quest-detail/quest-comments/comment/comment.service';

@Component({
  selector: 'app-log-activity',
  templateUrl: './log-activity.component.html',
  styleUrls: ['./log-activity.component.scss'],
  providers: [CommentService]
})
export class LogActivityComponent implements OnInit {
  activityForm: UntypedFormGroup;
  eventsSubject: Subject<void> = new Subject<void>();
  allActivities: SelectedActivityForTask[] = [];

  @Input('questId') questId: number;

  @Input('userId') userId: number;
  
  @Input('teamId') teamId: number = null;

  @Input('isEditActivity') isEditActivity: boolean = false;

  @Input('editActivityData') editActivityData: any;

  formSubmitted: boolean = false;

  availableAttributes: Array<any> = [];

  @ViewChild('imageInputElem', {read: ElementRef, static: true}) imageInputElem: ElementRef;

  selectedImage: File;

  @ViewChild('imageUploadBox', { read: ElementRef }) imageUploadBox: ElementRef;

  minorValueOptions: string[] = ['.0', '.1', '.2', '.3', '.4', '.5', '.6', '.7', '.8', '.9'];

  isLoading: boolean = false;

  inputValueType: string = 'text';

  timeChangeSub: any = null;

  LOG_ACTIVITY_POST_CHARACTER_LIMIT = LOG_ACTIVITY_POST_CHARACTER_LIMIT;

  logCommentLength: number = 0;

  isAttributeCompleted: boolean = false;

  ngxConfig = {...QUILL_CONFIG};

  uploadImageSubmit: boolean = false;

  doesImageExists: boolean = false;

  isAssetUploading: boolean = false;

  removeImages: boolean = false;

  isFormValuesChanged: boolean = false;

  allTasks: QuestTask[] = [];

  filteredTasks: QuestTask[] = [];

  filteredActivities: SelectedActivityForTask[] = [];

  taskGroupLength: number = 0;

  @Input('preSelectTaskId') preSelectTaskId: number;

  loggedCommentText: string = '';

  environment = environment;

  timeChangeSubscription: any;

  stepChangeSubscription: any;

  showStepsField: boolean = false;

  selectedAttributeVal: any;

  countChangeSubscription: any;

  hideActivitySelector: boolean = false;

  milestoneControlsDisabledForTask: boolean = true;

  activityAttributesValue: Array<{label: string; value: string | number;}> = [];

  isTemplate: boolean = false;

  constructor(private activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private questService: QuestService,
    private notifier: NotifierService,
    private store: Store<AppState>,
    private logActivityService: LogActivityService,
    private router: Router,
    private cdr: ChangeDetectorRef) {

    this.activityForm = this.formBuilder.group({
      pillarId: [''],
      activityId: [null, Validators.required],
      attributeValue: ['1'],
      title: [''],
      comment: ['', [Validators.maxLength(this.LOG_ACTIVITY_POST_CHARACTER_LIMIT)]],
      location: [null],
      tags: [''], 
      images: [null],
      time: formBuilder.group({
        hour: [null],
        minute: [null],
        second: [null]
      }), 
      taskToComplete: [null],
      calculatedSteps: [0],
      stepsOverride: [0],
      activityRecordListId: [null],
      activityRecordListPoints: [null],
      durationValue: [null],
      countValue: [null],
      completedValue: [null],
      volumeObject: formBuilder.group({
        value: [null],
        unit: [null]
      }),
      volumeValue: [null], // it will be used to save final value
      
      distanceObject: formBuilder.group({
        value: [null],
        unit: [null]
      }),
      distanceValue: [null],  // it will be used to save final value
      elevationObject: formBuilder.group({
        value: [null],
        unit: [null]
      }),
      elevationValue: [null],  // it will be used to save final value
      repsValue: [null],
      amountValue: [null]
    });
  
  } 

  ngOnInit() {
    if (this.isEditActivity) {
      this.activityForm.removeControl('pillarId');
      this.activityForm.removeControl('activityId');
      this.activityForm.removeControl('time');

      this.activityForm.patchValue({
        title: this.editActivityData['title'],
        comment: this.editActivityData['comment'],
        images: this.editActivityData['images']
      });

      this.loggedCommentText = this.editActivityData['comment'];

      // add the attributes 
      this.activityAttributesValue = [];
      if (this.editActivityData.completedValue) {
        this.activityAttributesValue.push({
          label: 'Completed',
          value: null
        })
      } 
      
      if (this.editActivityData.countValue) {
        this.activityAttributesValue.push({
          label: 'Count',
          value: this.editActivityData.countValue
        })
      }
      
      if (this.editActivityData.distanceValue) {
        this.activityAttributesValue.push({
          label: 'Distance',
          value: this.logActivityService.convertGenericToNormal('distance', this.editActivityData.distanceValue)
        })
      }

      if (this.editActivityData.elevationValue) {
        this.activityAttributesValue.push({
          label: 'Elevation',
          value: this.logActivityService.convertGenericToNormal('elevation', this.editActivityData.elevationValue)
        })
      }
      
      if (this.editActivityData.durationValue) {
        this.activityAttributesValue.push({
          label: 'Duration',
          value: this.logActivityService.convertGenericToNormal('duration', this.editActivityData.durationValue)
        })
      }
      
      if (this.editActivityData.repsValue) {
        this.activityAttributesValue.push({
          label: 'Reps',
          value: this.editActivityData.repsValue
        })
      }
      
      if (this.editActivityData.volumeValue) {
        this.activityAttributesValue.push({
          label: 'Volume',
          value: this.logActivityService.convertGenericToNormal('volume', this.editActivityData.volumeValue)
        })
      }
      
      if (this.editActivityData.amountValue) {
        this.activityAttributesValue.push({
          label: 'Amount',
          value: this.logActivityService.convertGenericToNormal('amount', this.editActivityData.amountValue)
        })
      }
    } else {
      this.getActivityOfQuest();
    }

    setTimeout(() => {
      this.activityForm.valueChanges.subscribe(res => {
        if (!this.isFormValuesChanged) this.isFormValuesChanged = true;
      });
    }, 500);

    this.store.select('quest').subscribe(questState => {
      this.isTemplate = questState.selectedQuestDetail && questState.selectedQuestDetail.quest.isTemplate;
    });
  }

  getActivityOfQuest() {
    this.hideActivitySelector = false;
    this.questService.getActivitiesForQuest(this.questId).subscribe((res: any) => {
      this.allActivities = res;

      /* hide activity if "sharing a post" is the only activity */
      if (res && res.length === 1 && res[0].id === 184) {
        this.hideActivitySelector = true;
      }

      this.store.select('dlMilestones').pipe(take(1)).subscribe(milestonesState => {
        let incompleteTasksTotal = [];
        let incompleteTasks = [];
        let completeTasks = [];
        /*
        * adding "NONE" as a task which has "Sharing a Post" as an activity by default.
        */
        this.filteredTasks.push(
          {
            "id": Math.floor(Math.random() * 1000),
            "questId": this.questId,
            "userId": this.userId,
            "linkedQuestId": null,
            "task": "",
            "taskCompleted": "FALSE",
            "video": null,
            "taskCompletionDate": null,
            "order": 0,
            "title": "None",
            "activities": [
              {
                "id": 184,
                "activityName": "Sharing a post",
                "pillarName": "social"
              }
            ],
            groupId: 1,
            groupName: null,
            index: 0,
            groupIndex: 0
        } as any);

        milestonesState.milestones.forEach((tGroup, i) => {
          if ((!environment.production && tGroup.id !== 2205) || (environment.production && tGroup.id !== 7531 && tGroup.id !== 7395)) {
            incompleteTasks = [];
            completeTasks = [];

            if (tGroup.viewType === 'task' && tGroup.groupStatus !== 'HIDDEN') {
              this.taskGroupLength += 1;// i + 1;

              tGroup.questTasks.forEach((task, j) => {
                let _task: any = task;
                _task.groupId = tGroup.id;
                _task.groupName = tGroup.name;
                _task.index = j;
                _task.groupIndex = i;
      
                this.allTasks.push(_task);
  
                if (_task.isTaskCompleted) {
                  // _task.disabled = true;
                  completeTasks.push(_task);
                } else {
                  incompleteTasks.push(_task);
                  incompleteTasksTotal.push(_task);
                }
              });  
            }

            this.filteredTasks.push(...incompleteTasks, ...completeTasks);
          }
        });

        if ((!environment.production && (this.questId === 2450 || this.questId === 2462 || this.questId === 2504)) || (environment.production && this.questId === 1220)) {
          // for quest id 2450
          let _taskId = !environment.production ? Number(27049) : Number(759793);
          if (this.questId === 2462) {
            _taskId = !environment.production ? Number(27104) : 0;
          } else if (this.questId === 2504) {
            _taskId = !environment.production ? Number(27539) : 0;
          }
          this.filteredTasks = this.filteredTasks.filter(_t => Number(_t.id) === _taskId);
        }
  
        if (incompleteTasksTotal.length === 1) {
          this.activityForm.patchValue({
            taskToComplete: incompleteTasksTotal[0].id
          });
  
          this.selectTask(incompleteTasksTotal[0]);
          this.isFormValuesChanged = true;
        } else if (this.preSelectTaskId) {
          this.activityForm.patchValue({
            taskToComplete: this.preSelectTaskId
          });

          this.selectTask(this.filteredTasks.find(t => t.id === this.preSelectTaskId));
        } else if (this.filteredTasks.length === 1) {
          this.activityForm.patchValue({
            taskToComplete: this.filteredTasks[0].id
          });
  
          this.selectTask(this.filteredTasks[0]);
        }
      });
    })
  }

  selectTask(event) {
    this.filteredActivities = [];
    event.activities.forEach(element => {
      this.filteredActivities.push(this.allActivities.find(act => act.id === element.id));
    });

    if (event.activityRecordListId) {
      this.activityForm.patchValue({
        activityRecordListId: event.activityRecordListId,
        activityRecordListPoints: event.asActivityRecordListPoints
      });
    }

    if (this.filteredActivities.length === 1) {
        this.hideActivitySelector = this.filteredActivities[0] && this.filteredActivities[0].id === 184;

        this.activityForm.patchValue({
          activityId: this.filteredActivities[0].id
        });
      this.selectedActivity(this.filteredActivities[0]);
    }

    this.store.select('dlMilestones').pipe(take(1)).subscribe(milestoneState => {
      milestoneState.milestones.forEach(tGroup => {
        const _ind = tGroup.questTasks.findIndex(task => task.id === event.id);

        if (_ind > -1) {
          this.milestoneControlsDisabledForTask = tGroup.milestoneControlsDisabled;
        }
      });
    })
  }

  selectedActivity(activityEvent) {
    const selectedAct = activityEvent;
    this.activityForm.patchValue({
      pillarId: selectedAct ? selectedAct['pillarId'] : null
    });

    this.getAttributesOfActivity(activityEvent.id);
    this.closedDropdown();
  }

  textChanged($event) {
    this.logCommentLength = $event.editor.getLength();
    this.loggedCommentText = $event.editor.getText();
    
    if ($event.editor.getLength() > LOG_ACTIVITY_POST_CHARACTER_LIMIT) {
      $event.editor.deleteText(LOG_ACTIVITY_POST_CHARACTER_LIMIT, $event.editor.getLength());
    }
  }


  closeModal(isSuccess: boolean = false): void {
    if (!isSuccess && (this.isFormValuesChanged || this.doesImageExists)) {
      if (window.confirm('Any changes made will be lost. Are you sure you want to close?')) {
        this.removeImages = true;
        this.doesImageExists = false;

        this.activeModal.close(isSuccess);
        this.activeModal.dismiss();
      }

      return;
    }

    this.activeModal.close(isSuccess);
    this.activeModal.dismiss();
  }

  getAttributesOfActivity(activityId) {
    this.isAttributeCompleted = false;

    this.activityForm.patchValue({
      time: {
        hour: null,
        minute: null,
        second: null
      },
      calculatedSteps: 0,
      stepsOverride: 0,
      durationValue: null,
      countValue: null,
      volumeObject: {
        value: null,
        unit: null
      },
      volumeValue: null,      
      distanceObject: {
        value: null,
        unit: null
      },
      distanceValue: null,
      elevationObject: {
        value: null,
        unit: null
      },
      elevationValue: null,
      repsValue: null,
      amountValue: null
    });

    this.clearAllValidatorsFromTime();
    if (this.timeChangeSub) this.timeChangeSub.unsubscribe();

    if (this.countChangeSubscription) this.countChangeSubscription.unsubscribe();

    this.activityForm.clearValidators();
    this.activityForm.setErrors(null); 
    
    this.activityForm.controls.activityId.setValidators([Validators.required])
    this.availableAttributes = [];

    if (!this.activityForm.value.activityRecordListId) {
      this.clearAllValidatorsFromTime();
      this.clearValidatorsAndErrorsFromFields();

      return;
    }

    this.questService.getAttributesOfActivity(activityId, this.activityForm.value.activityRecordListId).subscribe(res => {
      // remove validators from all attribute fields
      this.clearAllValidatorsFromTime();
      this.clearValidatorsAndErrorsFromFields();

      if (res && res['attributes'] && res['attributes'].length > 0) {
        this.availableAttributes = res['attributes'];

        if (((environment.production && (this.questId === 1239 || this.questId === 1275)) || (!environment.production && this.questId === 2523))) {
          this.activityForm.controls.title.setValidators([Validators.required]);
          this.activityForm.controls.comment.setValidators([Validators.required]);

          return;
        }

        // add validators to respective controls 
        this.availableAttributes.forEach(attr => {

          const _attrType = this.showFieldOfType(attr);
          switch (_attrType) {
            case 'duration':
              if (attr.isRequired) this.setTimeValidators();

              if (attr.steps > 0) {
                if (this.timeChangeSubscription) this.timeChangeSubscription.unsubscribe();

                // bind the duration change and calculate steps
                this.timeChangeSubscription = (this.activityForm.controls.time as UntypedFormGroup).valueChanges.subscribe(timeState => {
                  const _calcSteps = Math.round(((Number(timeState.hour) * 60 * 60) + (Number(timeState.minute) * 60) + Number(timeState.second)) * Number(attr.steps));

                  this.activityForm.patchValue({
                    calculatedSteps: _calcSteps,
                    stepsOverride: _calcSteps
                  }, {emitEvent: false});
                });
              }

              if (this.stepChangeSubscription) this.stepChangeSubscription.unsubscribe();

              if (attr.steps > 0 && attr.allowStepsOverride) {
                // this.activityForm.controls.stepsOverride.enable();
                this.activityForm.controls.stepsOverride.setValidators([Validators.required, Validators.min(1)]);

                this.stepChangeSubscription = this.activityForm.controls.stepsOverride.valueChanges.subscribe(stepVal => {
                  if (stepVal && Number(stepVal) > 0) {
                    this.clearAllValidatorsFromTime();
                  } else {
                    this.setTimeValidators();
                  }
                });
              }
              else {
                // this.activityForm.controls.stepsOverride.disable();
              }

              break;

            case 'count':
              if (attr.isRequired) this.activityForm.controls.countValue.setValidators([Validators.required]);
              
              break;
              
            case 'completed':
              if (attr.isRequired) this.activityForm.controls.completedValue.setValidators([Validators.required]);

              break;

            case 'volume':
              if (attr.isRequired) this.activityForm.controls.volumeObject.setValidators([Validators.required]);

              if (attr.units && attr.units.length > 0) {
                this.activityForm.controls.volumeObject.patchValue({
                  unit: attr.units[0].id
                })
              }

              break;

            case 'distance':
              if (attr.isRequired) this.activityForm.controls.distanceObject.setValidators([Validators.required]);

              if (attr.units && attr.units.length > 0) {
                this.activityForm.controls.distanceObject.patchValue({
                  unit: attr.units[0].id
                })
              }

              break;
            
            case 'elevation':
              if (attr.isRequired) this.activityForm.controls.elevationObject.setValidators([Validators.required]);

              if (attr.units && attr.units.length > 0) {
                this.activityForm.controls.elevationObject.patchValue({
                  unit: attr.units[0].id
                })
              }

              if (this.countChangeSubscription) this.countChangeSubscription.unsubscribe();

              // check if count exists then get it calculated
              if (this.availableAttributes.filter(_avAtr => _avAtr.attributeName.toLowerCase() === 'count').length > 0) {
                this.countChangeSubscription = this.activityForm.controls.countValue.valueChanges.subscribe(countVal => {
                  if (countVal && Number(countVal) > 0) {
                    this.convertCountToElevation(Number(countVal));
                  } else {
                    this.activityForm.get('elevationObject').patchValue({
                      value: null
                    }, {emitEvent: false});
                  }
                });
              }

              break;

            case 'reps':
              if (attr.isRequired) this.activityForm.controls.repsValue.setValidators([Validators.required]);

              break;

            case 'amount':
              if (attr.isRequired) this.activityForm.controls.amountValue.setValidators([Validators.required]);

              break;

            default:
              break;
          }
        });
        // this.activityForm.controls.attributeId.setValidators([Validators.required]);

        // check if there is only 1 activity then make it preselected
        // setTimeout(() => {
        //   if (this.availableAttributes.length === 1) {
            // this.activityForm.patchValue({
              // attributeId: this.availableAttributes[0]['id'],
              // attributeName: this.availableAttributes[0]['attributeName']
            // });

            // this.eventsSubject.next();

            // this.selectedAttribute(this.availableAttributes[0]);
        //   }
        // }, 100);
      }
    }, err => {
      this.clearAllValidatorsFromTime();
      this.clearValidatorsAndErrorsFromFields();
    })
  }

  clearValidatorsAndErrorsFromFields() {
    this.clearAllValidatorsFromTime();
    if (this.timeChangeSub) this.timeChangeSub.unsubscribe();

    this.activityForm.controls.volumeObject.clearValidators();
    this.activityForm.controls.volumeObject.setErrors(null);
    this.activityForm.controls.distanceObject.clearValidators();
    this.activityForm.controls.distanceObject.setErrors(null);
    this.activityForm.controls.elevationObject.clearValidators();
    this.activityForm.controls.elevationObject.setErrors(null);
    this.activityForm.controls.repsValue.clearValidators();
    this.activityForm.controls.repsValue.setErrors(null);
    this.activityForm.controls.amountValue.clearValidators();
    this.activityForm.controls.amountValue.setErrors(null);
    this.activityForm.controls.countValue.clearValidators();
    this.activityForm.controls.countValue.setErrors(null);
  }

  public get getTimeForm(): UntypedFormGroup {
    return this.activityForm.controls.time as UntypedFormGroup;
  }


  setTimeValidators(): void {
    const timeGroup = this.getTimeForm;
    this.setAllValidatorsToTime();

    if (this.timeChangeSub) this.timeChangeSub.unsubscribe();

    // call only when they are set required
    this.timeChangeSub = timeGroup.valueChanges.subscribe(timeVal => {
      this.clearAllValidatorsFromTime();

      if (timeVal.hour > 0) {
        this.setHourValidation(timeGroup);
        if (timeVal.hour > 23) {
          timeGroup.controls.hour.setErrors({max: 23});
        }
      }

      if (timeVal.minute > 0) {
        this.setMinuteValidation(timeGroup);
        if (timeVal.minute > 59) {
          timeGroup.controls.minute.setErrors({max: 59});
        }
      }

      if (timeVal.second > 0) {
        this.setSecondValidation(timeGroup);
        if (timeVal.second > 59) {
          timeGroup.controls.second.setErrors({max: 59});
        }
      }
      
      if (!timeVal.hour && !timeVal.minute && !timeVal.second) {
        this.setAllValidatorsToTime()
      }
    });
  }

  setAllValidatorsToTime() {
    const timeGroup = this.getTimeForm;
    this.setHourValidation(timeGroup);
    this.setMinuteValidation(timeGroup);
    this.setSecondValidation(timeGroup);
  }

  setHourValidation(timeGroup) {
    timeGroup.controls.hour.setValidators([Validators.required, Validators.min(1), Validators.max(23)]);
  }

  setMinuteValidation(timeGroup) {
    timeGroup.controls.minute.setValidators([Validators.required, Validators.min(1), Validators.max(59)]);
  }

  setSecondValidation(timeGroup) {
    timeGroup.controls.second.setValidators([Validators.required, Validators.min(1), Validators.max(59)]);
  }

  clearAllValidatorsFromTime() {
    const timeGroup = this.getTimeForm;
    timeGroup.controls.hour.clearValidators();
    timeGroup.controls.hour.setErrors(null);
    timeGroup.controls.minute.clearValidators();
    timeGroup.controls.minute.setErrors(null);
    timeGroup.controls.second.clearValidators();
    timeGroup.controls.second.setErrors(null);
  }

  submitActivity(): void {
    this.activityForm.markAllAsTouched();

    if (!this.isEditActivity && !this.activityForm.value.taskToComplete) {
      this.notifier.notify('error', 'Please select a task');
      
      return;
    }

    if (!this.isEditActivity && !this.activityForm.value.activityId) {
      this.notifier.notify('error', 'Please select an activity');
      
      return;
    }

    if (!this.isEditActivity && this.activityForm.invalid) {
      this.notifier.notify('error', 'Please fill required fields');

      return;
    }

    this.isLoading = true;

    if (this.doesImageExists) {
      // upload image and then run add activity
      this.uploadImageSubmit = true;
    } else {
      this.addActivity();
    }
    return;
  }

  imageUploaded(event) {
    this.uploadImageSubmit = false;
    this.isFormValuesChanged = true;
    this.addActivity(event.assetData);
  }

  addActivity(imageResp: QuestGalleryImage[] = []) {
    this.store.select('quest').pipe(take(1)).subscribe(questState => {
      if (questState.selectedQuestDetail.quest.isTemplate) {
        return;
        /* this.removeImages = true;
        this.notifier.notify('success', 'Activity logged successfully.');
        this.closeModal(true);
        */
      } else {
        this.store.select('dlMilestones').pipe(take(1)).subscribe(milestoneState => {
          let payload = {
            ...this.activityForm.value
          };
      
          if (imageResp.length > 0) payload.images = imageResp;
      
          if (!this.isEditActivity) {
            if (this.activityForm.value.volumeObject.value && (!this.activityForm.value.volumeObject.unit || this.activityForm.value.volumeObject.unit === '')) {
              this.notifier.notify('error', 'Please select the unit for volume');
              this.isLoading = false;
              
              return;
            }

            if (this.activityForm.value.distanceObject.value && (!this.activityForm.value.distanceObject.unit || this.activityForm.value.distanceObject.unit === '')) {
              this.notifier.notify('error', 'Please select the unit for distance');
              this.isLoading = false;

              return;
            }

            if (this.activityForm.value.elevationObject.value && (!this.activityForm.value.elevationObject.unit || this.activityForm.value.elevationObject.unit === '')) {
              this.notifier.notify('error', 'Please select the unit for elevation');
              this.isLoading = false;

              return;
            }

            this.availableAttributes.forEach(_attribute => {
              const _attrType = this.showFieldOfType(_attribute);
              switch (_attrType) {
                case 'duration':
                  const timeVal = this.activityForm.value.time;
                  payload.durationValue = (timeVal.hour || 0)+ ':' + (timeVal.minute || 0) + ':' + (timeVal.second || 0);//this.logActivityService.convertTimeToGeneric(timeVal);
                  break;

                // case 'count':
                case 'completed':
                  payload.completedValue = this.activityForm.value.completedValue && Number(this.activityForm.value.completedValue) === 10 ? 1 : 0;
                  break;

                case 'volume':
                  payload.volumeValue = this.logActivityService.convertValueToGeneric('volume', this.activityForm.value.volumeObject.value, this.activityForm.value.volumeObject.unit);
                  break;

                case 'distance':
                  payload.distanceValue = this.logActivityService.convertValueToGeneric('distance', this.activityForm.value.distanceObject.value, this.activityForm.value.distanceObject.unit);
                  break;
                
                case 'elevation':
                  payload.elevationValue = this.logActivityService.convertValueToGeneric('elevation', this.activityForm.value.elevationObject.value, this.activityForm.value.elevationObject.unit);
                  break;

                // case 'reps':
                case 'amount':
                  payload.amountValue = this.logActivityService.convertValueToGeneric('amount', this.activityForm.value.amountValue);
                  break;

                default:
                  break;
              }   
            });
          }

          // adding teamId with the paylaod
          payload.teamId = this.teamId || null;
          payload.isAnonymous = payload.isAnonymous || false;

          this.isLoading = true;
      
          const apiToCall = this.isEditActivity ? this.questService.updateActivity(this.editActivityData['actvityRecordValueId'], payload) : this.questService.logActivity(this.questId, payload);
          apiToCall
            .pipe(
              finalize(() => (this.isLoading = false))
            )
            .subscribe((res: LoggedActivity) => {
              if (res && res.actvityRecordValueId) {
                this.notifier.notify('success', this.isEditActivity ? 'Activity updated successfully.' : 'Activity logged successfully.');
                this.isEditActivity ? this.store.dispatch(new UpdateQuestLoggedActivity(res)) : this.store.dispatch(new QuestNewLoggedActivity(res));
      
                this.store.dispatch(new GetQuestGalleryAssets(this.questId, this.userId));

                const _task: any = this.allTasks.find(t => t.id === this.activityForm.value.taskToComplete);
                if (!this.milestoneControlsDisabledForTask && this.activityForm.value.taskToComplete && !_task.isTaskCompleted) {
                  this.questService.updateTaskStatus(_task, _task.index, _task.groupIndex, this.cdr);
                }

                setTimeout(() => {
                  
                if (!this.isEditActivity) {
                  // open feed
                  if (this.teamId) {
                    this.router.navigate(['quest-detail', this.questId, this.userId, this.teamId, 'feed'], {
                      queryParams: {
                        postId: res.actvityRecordValueId
                      }
                    })
                  } else {
                    this.router.navigate(['quest-detail', this.questId, this.userId, 'feed'], {
                      queryParams: {
                        postId: res.actvityRecordValueId
                      }
                    })
                  }
                }
                
                this.closeModal(true);
              }, 1000);

              } else {
                this.notifier.notify('error', this.isEditActivity ? 'Activity updating failed.' : 'Activity logging failed.');
              }
            }, err => {
              this.notifier.notify('error', this.isEditActivity ? 'Activity updating failed.' : 'Activity logging failed.');
            });
        });
      }
    });
  }

  fixTimeField(event, fieldName: string): void {
    let value = ('00' + (event.target.value).toString());
    value = value.substring(value.length - 2, value.length);
    const timeGroup = this.getTimeForm;
    timeGroup.get(fieldName).setValue(value);
    event.target.value = value;
  }

  openedDropdown() {
    setTimeout(() => {  
      const elems = document.getElementsByClassName("ng-select-opened");
      for (let index = 0; index < elems.length; index++) {
        (elems[index] as HTMLObjectElement).style.zIndex = '99';
      };
    }, 300);
  }

  closedDropdown() { 
    setTimeout(() => {  
      const elems = document.getElementsByClassName("ng-select");
      for (let index = 0; index < elems.length; index++) {
        (elems[index] as HTMLObjectElement).style.zIndex = '9';
      };
    }, 500);
  }

  checkImageExist(event) {
    this.isFormValuesChanged = true;
    this.doesImageExists = event;
  }

  /**
   * @method: assetUploading
   * @param event 
   * @description: get boolean value and set the asset upload status
   */
  assetUploading(event): void {
    this.isFormValuesChanged = true;
    this.isAssetUploading = event;
  }

  isLogActivityValid(): boolean {
    return this.activityForm.valid;
  }

  showDistanceFieldsFunc(attribute): boolean {
    return attribute['attributeName'].toLowerCase() === 'distance';
  }

  getAttributeLabel(attribute): string {
    if (this.showDistanceFieldsFunc(attribute)) {
      return 'Distance';
    } 
    
    if (attribute['attributeName'].toLowerCase() === 'time' || attribute['attributeName'].toLowerCase() === 'duration') {
      return 'Duration';
    }

    return attribute.attributeName;
  }

  showStepsTooltip(attribute): boolean {
    return attribute['attributeName'].toLowerCase() === 'steps';
  }

  stepsPerMinute(attributeElem: any): number {
    return attributeElem && attributeElem.steps ? Math.round(attributeElem.steps * 60) : 0;
  }

  showFieldOfType(attribute) {
    return attribute['attributeName'].toLowerCase();
  }

  convertCountToElevation(countVal: number): void {
    if (Number(this.activityForm.value.activityId) === 234) {
      // activity is bouldering
      this.activityForm.get('elevationObject').patchValue({
        value: countVal * 15
      }, {emitEvent: false});
    } else {
      // if (Number(this.activityForm.value.activityId) === 233) {
      // activity is Top roping -> 233 OR anything else 
      this.activityForm.get('elevationObject').patchValue({
        value: countVal * 40
      }, {emitEvent: false});
    }
  }

  attributeTrackBy(id, attr) {
    return attr.id;
  }
}
