import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { NotifierService } from 'angular-notifier';
import { ObservableInput, Subscription, finalize, forkJoin, of, take } from 'rxjs';
import { ManageQuestPayload } from 'src/app/_interface/quest-service.types';
import { QuestService } from 'src/app/_services/quest.service';
import { VALIDATION_MESSAGES, ValidationMessages } from 'src/app/app.constants';
import * as fromApp from '../../../_store/app.reducers';
import { QUILL_CONFIG } from '../../../app.config';
import * as fromProfile from '../../pages/profile/store/profile.reducer';
import { DlMilestonesService } from '../../main/dl-milestones/service/dl-milestones.service';
import { TaskGroup } from 'src/app/_interface/dl-milestones.typings';
import { NewTaskGroupAdded } from 'src/app/_interface/dl-milestones.actions';
import { QuestTask } from 'src/app/_interface/quest.types';

@Component({
  selector: 'app-quest-create',
  templateUrl: './quest-create.component.html',
  styleUrls: ['./quest-create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestCreateComponent implements OnInit {
  questCreateForm: FormGroup;

  quest_description_length: number = 5000;

  @Input('creationOf') creationOf: string = 'quest';

  ngxConfig = {...QUILL_CONFIG};

  validationMessages: ValidationMessages = VALIDATION_MESSAGES;

  isLoading: boolean = false;

  userInfo: fromProfile.State;

  // @ViewChild('activityOptionsBox', { read: ElementRef }) activityOptionsBox: ElementRef;

  // showActivityOptions: boolean = false;

  // filteredActivities: any[] = [];

  selectedActivities: any[] = [];

  // activityApiLoading: boolean = false;

  // runMoreApi: boolean = true;
  
  // activityApi: Subscription;
  
  // activityPage: number = 0;
  
  // activitySearchText: string = '';

  taskForm: FormGroup;

  submitTaskFrom: boolean = false;

  constructor(private formBuilder: FormBuilder,
    private notifier: NotifierService,
    private questService: QuestService,
    private router: Router,
    private store: Store<fromApp.AppState>,
    private modalService: NgbModal,
    private milestoneService: DlMilestonesService,
    private cdr: ChangeDetectorRef) {
    this.createForm();
  }

  /*
  @HostListener('document:click', ['$event'])
  outsideClick(event): void {
    if (this.activityOptionsBox && !this.activityOptionsBox.nativeElement.contains(event.target)) {
      this.showActivityOptions = false;
    }
  }
  */

  ngOnInit(): void {
    this.ngxConfig.counter = {container: '#counter', unit: 'character', maxLength: this.quest_description_length};

    this.store.select('userInfo').pipe(take(1)).subscribe((state: fromProfile.State) => {
      this.userInfo = state;

      this.cdr.detectChanges();
    });

    /*
    this.questCreateForm.controls.searchActivity.valueChanges.subscribe(res => {
      this.activityPage = 0;
      this.filteredActivities = [];
      this.runMoreApi = true;
      this.activitySearchText = res;

      this.getActivities();
    });
    */

    this.taskForm = this.milestoneService.createTaskForGroupForm(true);

    this.cdr.detectChanges();
  }

  createForm() {
    this.questCreateForm = this.formBuilder.group({
      questName: [null, [Validators.required, , Validators.maxLength(75), Validators.minLength(3)]],
      questDescription: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(this.quest_description_length)]],
      // searchActivity: [null],
      mode: [null, [Validators.required]]
    });
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  createQuest() {
    if (this.creationOf === 'event') {
      this.questCreateForm.patchValue({
        mode: 'diyMode'
      });
    }

    this.selectedActivities = [{
      "id": 184,
      "activityName": "Sharing a post"
    }];

    if (this.questCreateForm.invalid) {
      this.notifier.notify('error', 'Please complete required fields');

      return;
    }

    // if (!this.selectedActivities || this.selectedActivities.length < 1) {
    //   this.notifier.notify('error', 'Please select at least one activity');

    //   return;
    // }
    this.submitTaskFrom = true;

    this.cdr.detectChanges();
  }

  buildCreateQuestPayload(): ManageQuestPayload {
    let payload: ManageQuestPayload = {
      ...this.questCreateForm.value,
      pillar: null,
      questTasks: null,
      admins: [],
      photo: '',
      privacy: 'public',
      copyAllowed: false,
      // editableMilestones: false,
      invites: [],
      backBtnDisabled: true,
      fundraising: false,
      fundraisingConfig: null,
      multiTeamsAllowed: false,
      accentColor: '',
      themeColor: '',
      revertCustomColor: false,
      questVideoUrl: null,
      tasksGroups: null,
      isTemplate: false,
      backBtnEnabled: false,
      // taskViewDisabled: this.creationOf === 'event',
      leaderboardEnabled: false,
      coverCenterX: null,
      coverCenterY: null,
      coverZoomValue: null,
      hideMembers: false,
      hideFeed: false,
      hideTeams: false,
      hidePeople: false,
      startBtnDisabled: false
    }

    return payload;
  }

  /*
  showAndSearchOptions() {
    this.showActivityOptions = true;
    this.getActivities();
  }

  getActivities() {
    if (this.runMoreApi) {
      if (this.activityApi) {
        this.activityApi.unsubscribe();
      }

      this.activityApiLoading = true;
      this.activityApi = this.questService.getActivities(this.activityPage, null, this.activitySearchText).subscribe((res: Array<any>) => {
        if (res && (res as Array<any>).length > 0) {
          this.filteredActivities.push(...res);
          
          this.filterSelectedActivities();
        } else {
          this.runMoreApi = false;
        }
        this.activityApiLoading = false;
      }, err => {
        this.activityApiLoading = false;
      });
    }
  }

  onScrollActivity() {
    this.activityPage += 1;
    this.getActivities();
  }

  filterSelectedActivities() {
    this.selectedActivities.forEach(_act => {
      let itemIndex = this.filteredActivities.findIndex(fAct => fAct.id === _act.id);
      if (itemIndex > -1) {
        this.filteredActivities.splice(itemIndex, 1);
      }
    });
  }

  selectedActivity(activity) {
    const _act = activity;
    // console.log('155', _act);
    this.selectedActivities.push(_act);

    // this.questCreateForm.patchValue({
    //   searchActivity: ''
    // });
    this.showActivityOptions = false;
    this.activityPage = 0;
    // this.allActivities = [];
    this.filteredActivities = [];
    this.runMoreApi = true;
    this.activitySearchText = '';
  }

  removeActivity(activity) {
    const removeIndex = this.selectedActivities.findIndex(act => act.id === activity.id)
    this.selectedActivities.splice(removeIndex, 1);
  }
  */

  taskUpdated(event) {
    if (event.type === 'task-error') {
      // do nothing
      this.submitTaskFrom = false;

      this.cdr.detectChanges();
    } else if (event.type === 'updated-task') {
      // run create quest
      console.log('239', event);
      // return;
      this.isLoading = true;

      this.questService.createQuest(this.buildCreateQuestPayload())
        // .pipe(finalize(() => this.isLoading = false))
        .subscribe(res => {

          let groupSources: [ObservableInput<TaskGroup>, ObservableInput<TaskGroup>] = [
            this.milestoneService.createNewTaskGroup(res.id, {
              groupName: 'System Activities',
              groupOwnerId: this.userInfo.id,
              groupOrder: 0,
              viewType: 'task',
              editableMilestones: false,
              milestoneControlsDisabled: true,
              tasksExpanded: false,
              logActivityCompulsory: false,
              taskViewDisabled: true,
              groupStatus: 'HIDDEN'
            }),


            this.creationOf === 'event' 
              ? of(null).pipe() 
              : this.milestoneService.createNewTaskGroup(res.id, {
                groupName: 'Tasks',
                groupOwnerId: this.userInfo.id,
                groupOrder: 1,
                viewType: 'task',
                editableMilestones: false,
                milestoneControlsDisabled: false,
                tasksExpanded: false,
                logActivityCompulsory: true,
                taskViewDisabled: false,
                groupStatus: 'NORMAL'
              })
          ];

          this.cdr.detectChanges();

          forkJoin(groupSources).subscribe(([hiddenTaskGroup, normalTaskGroup]) => {
            this.store.dispatch(new NewTaskGroupAdded(hiddenTaskGroup));
            this.creationOf === 'event'  ? null : this.store.dispatch(new NewTaskGroupAdded(normalTaskGroup));

            // creating task inside hidden group
            let _arrHidden = [];
            this.selectedActivities.forEach(act => _arrHidden.push(act.id));

            let taskSources: [ObservableInput<QuestTask>, ObservableInput<QuestTask>] = [
              this.milestoneService.addMilestone(res.id, {
                title: 'Hidden Task',
                task: '',
                activities: this.selectedActivities,
                activitiesIds: _arrHidden,
                addedLink: '',
                imageUrl: null,
                videoUrl: null,
                video: null,
                embeddedVideo: null,
                linkUrl: null,
                linkPreview: null,
                linkedQuest: null,
                linkedQuestId: null,
                existingId: '',
                required: false,
                activityRecordListPoints: 1,
                groupIndex: 0,
                order: 0,
                questOwnerId: this.userInfo.id
              }),

              (this.creationOf === 'event' || (
                this.creationOf !== 'event' && (
                  !event.milestoneForm || 
                  (event.milestoneForm && !event.milestoneForm.title) || 
                  (event.milestoneForm && event.milestoneForm.title && event.milestoneForm.title === ''))
                )
              )
                ? of(null).pipe() 
                : this.milestoneService.addMilestone(res.id, {
                    ...event.milestoneForm,
                    groupIndex: 1
                  })
            ];
            forkJoin(taskSources)
              .subscribe(([hiddenTask, normalTask]) => {
                setTimeout(() => {
                  this.closeModal();
                }, 50);
        
                if (this.creationOf === 'event') {
                  this.notifier.notify('success', 'Event draft saved');
        
                  // redirect to event creation page
                  this.router.navigate(['/manage/event', res.id]);
        
                  return;
                }
        
                this.notifier.notify('success', 'Quest draft saved');
        
                // redirect to quest detail page
                this.router.navigate(['/quest-detail', res.id, this.userInfo.id, 0, 'details']);
              }, taskErr => {

              });
            
          }, () => {
            this.notifier.notify('error', 'Issue processing request. Please try again later.');
          });
        }, err => {
          console.log(err);
        });
    }
  }
}
